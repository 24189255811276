import React from 'react';
import './Statistics.css';
import increase from '../assets/increase.svg';

function Statistics() {
  return (
    <div className="statistics-section">
      <h2>Frontier health was created to solve the <b> global healthcare workforce crisis.</b></h2>
      <div className='line'> </div>
      <div className="statistics-cards">
        <div className="card">
          <h3>43%</h3>
          <p>of healthcare staff say they don’t have enough time to do their jobs.</p>
        </div>
        <div className="card">
          <h3>1/11</h3>
          <p>Nursing posts in the UK are vacant.</p>
        </div>
        <div className="card">

          <div className='stat-row'> 
          <img src={increase} className="increase"/>
                    <h3>100%</h3>

            </div>
            <p>in healthcare staff leaving their jobs due to work-life balance or health issues.</p>


        </div>

        <div className="card">
          <h3>30M</h3>
          <p>healthcare worker shortage by 2030.</p>
        </div>
      </div>

    </div>
  );
}

export default Statistics;
