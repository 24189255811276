import React from 'react';
import './Navbar.css';
import logo from '../assets/logo.jpg'; // Placeholder for your fourth image
import arrowlogo from '../assets/logo1.svg';


function Navbar() {
  return (
    <div className='navbar-container'>
    <div className="navbar">
      <div className="logo">
        <img className="img-logo" src={arrowlogo} alt="Logo" /> {/* Replace with actual logo path */}
        <h2> Frontier Health </h2>
      </div>  
      <div className="nav-links">
        <a href="/">About</a>
        <a href="#benefits-section">Product</a>
      </div>
      <div className="nav-button">
        <a href="#contact-form">
          <button>Request a Demo</button>
        </a>
      </div>
    </div>
    </div>
  );
}

export default Navbar;


// #653888