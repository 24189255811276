import React from 'react';
import './PrivacyNotice.css';  // Assuming you'll add styles here for better formatting.

function PrivacyNotice() {
  return (
    <div className="privacy-notice">
      <h1>Privacy Notice</h1>
      <p>
        The Privacy Notice details the organisation's acquiring, use and sharing of personal data 
        for the delivery of its services alongside its commitment to ensuring the privacy and security 
        of this data. It is drafted to be public-facing and support the requirements around transparency 
        and accountability for the use of an individual's data.
      </p>

      <h2>Introduction</h2>
      <p>
        This privacy notice tells you what to expect us to do with your personal information when you make contact with us or use one of our services. It explains:
      </p>
      <ul>
        <li>Why we are able to process your information;</li>
        <li>What purpose we are processing it for;</li>
        <li>Whether you have to provide it to us;</li>
        <li>How long we store it for;</li>
        <li>Whether there are other recipients of your personal information;</li>
        <li>Whether we intend to transfer it to another country; and</li>
        <li>Whether we do automated decision-making or profiling.</li>
      </ul>
      <p>
        This privacy notice explains what personal information we collect about those who visit our website and communicate with us, how we use it, the circumstances in which we may disclose it to others and how we keep it secure.
      </p>
      <p>
        We are committed to protecting and respecting your privacy. If you have any questions regarding this privacy notice and our approach to privacy, contact us via our online contact form.
      </p>

      <h2>Who we are</h2>
      <p>
        Frontier AI Ltd (‘Frontier AI’) is registered as a limited company (Companies House No. 15801722) in the United Kingdom. 
        To enable us to offer services that process personal data in the UK we have registered with the Information Commissioner's Office (ICO) under registration no. ZB737595.
      </p>

      <h2>The Information We Process</h2>
      <p>
        We obtain information about you when you use our website, when you contact us about products and services, for example by email or telephone, or during business with you or an organisation you represent.
      </p>

      <h3>Most of the personal information we process is provided to us directly by you for one of the following reasons:</h3>
      <ul>
        <li>You have made an enquiry to us.</li>
        <li>You have initiated or completed a commercial transaction with us.</li>
        <li>We are providing services to you or the organisation or entity you work for or represent.</li>
        <li>You have made a complaint to us.</li>
        <li>You have applied for a job with us.</li>
        <li>You work with or for us.</li>
        <li>You visit our website and request a demo or complete our contact form.</li>
      </ul>

      <h3>We also receive personal information indirectly, in the following scenarios:</h3>
      <ul>
        <li>Where we are providing services to the organisation or entity you work for or represent and they have provided your information to us.</li>
        <li>Where you have made your contact information available on your organisation's website and we use this to contact you and your organisation.</li>
        <li>Where you have made your contact information available via a social media platform and we use this to contact you and your organisation.</li>
        <li>You have applied for a job with us and we seek a reference from an organisation or individual you have previously worked for or with.</li>
        <li>An employee of ours gives your contact details as an emergency contact or a referee.</li>
      </ul>
      <p>
        In cases where we indirectly receive personal data as a data controller, we’ll contact you to let you know we are processing your personal information if it is not disproportionate or prejudicial to do so.
      </p>

      <h2>The Lawful Basis for the Processing of Personal Data</h2>
      <p>
        The table below describes the personal data we collect and our lawful basis for processing this data.
      </p>

      <p>
        When we process data on the basis of legitimate interests, we apply the following test to determine whether it is appropriate:
      </p>
      <ul>
        <li>Purpose – is the purpose of processing personal data legitimate?</li>
        <li>Necessity – is the processing necessary to fulfil that purpose?</li>
        <li>Balance – do the individual’s interests, rights or freedoms override the legitimate interest?</li>
      </ul>

      <h3>Purpose of Collection</h3>

      <table class="data-table">
  <thead>
    <tr>
      <th>Purpose of collection</th>
      <th>Data collected</th>
      <th>Purpose for collection</th>
      <th>Lawful basis for processing</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>To provide you with information and to deliver services</td>
      <td>Name, job title, company name, email address, telephone number, business sector.</td>
      <td>To provide further, related, information via email or telephone about the identified area of interest.<br />To provide appropriate information via email or telephone about products and services that you have requested (delivery of services).</td>
      <td>Legitimate interests<br />Performance of a contract</td>
    </tr>
    <tr>
      <td>Transactional information</td>
      <td>Name, registered address, email address, telephone number, and bank account details (for credit accounts).</td>
      <td>To enable invoicing for goods and services.<br />Contract management and in support of any contractual claim which may arise.<br />For accounting purposes.<br />For taxation purposes.</td>
      <td>Performance of a contract<br />Compliance with a legal obligation<br />Compliance with a legal obligation</td>
    </tr>
    <tr>
      <td>Frontier Co-worker User Profile</td>
      <td>Name, title, phone number, email address, role (e.g. Administrative Support), healthcare setting.</td>
      <td>To set up a profile to use Frontier Co-worker’s services.</td>
      <td>Performance of a contract</td>
    </tr>
    <tr>
      <td>Frontier Co-worker Services</td>
      <td>Name, Title, Phone number, Date of Birth, and Health data which includes:
        <ul>
          <li>NHS Number</li>
          <li>Gender</li>
          <li>Hospital visit information (appointment history)</li>
          <li>Past and future imaging</li>
          <li>Past and future histopathology reports</li>
          <li>Cancer Pathway information</li>
        </ul>
      </td>
      <td>To facilitate the delivery of Frontier Co-worker’s services.</td>
      <td>Performance of a task carried out in the public interest</td>
    </tr>
    <tr>
      <td>Data Subject Rights</td>
      <td>Name, email address, telephone number, and proof of ID (when necessary).</td>
      <td>To enable data subjects to exercise their rights over personal data.</td>
      <td>Compliance with a legal obligation</td>
    </tr>
  </tbody>
</table>

<p>Frontier AI Ltd also acts as a data processor when it provides services to the NHS and other healthcare organisations. The data controller in this context will likely seek to rely on the following lawful bases to process data:</p>

<ul>
  <li>To support the processing of data in support of the provision of NHS services, legal powers such as those provided within:
    <ul>
      <li>The National Health Service and Community Care Act 1990</li>
      <li>The NHS Act 2006</li>
      <li>The Health and Social Care Act 2012</li>
    </ul>
  </li>

  <li>To support the processing of personal data and special categories of data in accordance with the UK GDPR:
    <ul>
      <li>Art. 6(1)(e) - Public task</li>
      <li>Art. 9(2)(h) - healthcare purposes</li>
    </ul>
  </li>

  <li>To support the processing of personal data in accordance with the Data Protection Act 2018:
    <ul>
      <li>Condition 2 of Schedule 1 - Health and Social Care Purposes</li>
    </ul>
  </li>

  <li>To support the processing of confidential information in accordance with the Common Law Duty of Confidentiality:
    <ul>
      <li>Implied consent</li>
    </ul>
  </li>
</ul>


      <h2>Automated decision-making or profiling</h2>
      <p>We do not undertake any automated decision-making or profiling in relation to your personal data.</p>

      <h2>Retention</h2>
      <p>
        Personal data processed within Frontier AI will be retained for as long as necessary and where applicable in line with the retention periods specified in the <a href="https://transform.england.nhs.uk/information-governance/guidance/records-management-code/"> NHS England Records Management Code of Practice.</a>
      </p>

      <h2>Your data protection rights</h2>
<p>The rights available to you depend on our reason for processing your information. Under data protection law, you have rights including:</p>
<ul>
  <li><strong>Your right of access</strong> - You have the right to ask us for copies of your personal information.</li>
  <li><strong>Your right to rectification</strong> - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
  <li><strong>Your right to erasure</strong> - You have the right to ask us to erase your personal information in certain circumstances.</li>
  <li><strong>Your right to restriction of processing</strong> - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</li>
  <li><strong>Your right to object to processing</strong> - You have the right to object to the processing of your personal information in certain circumstances.</li>
  <li><strong>Your right to data portability</strong> - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
</ul>
<p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond.</p>

<p>You can contact us, including our Data Protection Officer (DPO), to take up any of these rights via email: <a href="mailto:help@frontierhealth.ai">help@frontierhealth.ai</a></p>

<h2>Sharing your information</h2>
<p>We will not share your information with any third parties for the purposes of direct marketing.</p>
<p>We use data processors, which are third parties that provide services for us. We have contracts in place with our data processors. This means that they cannot do anything with your personal information such as share it with other organisations unless we have instructed them to do it. They will hold your personal data securely and retain it for the period we instruct. When it is necessary for us to transfer your personal data outside of the UK, this will only be done in accordance with the UK GDPR and the Data Protection Act 2018 (DPA 2018).</p>
<p>In some circumstances, we are legally obliged to share information. For example, to comply with/under a court order. In any scenario, we’ll satisfy ourselves that we have a lawful basis on which to share the information and document our decision-making.</p>

<h2>International Transfers</h2>
<p>When personal data is transferred outside of the UK, the appropriate technical and organisational safeguards will be in place. All international transfers will only occur if it complies with the relevant legislation.</p>

<h2>Visitors to our website</h2>
<p>Where we do want to collect personally identifiable information through our website, we will make this clear at the point personal information is collected and we will explain what we intend to do with it.</p>

<h2>Use of cookies</h2>
<p>Like many other websites, our website uses cookies. ‘Cookies’ are small pieces of information sent by a website to your device and stored to enable that website to recognise you when you visit in the future. They can also be used to collect statistical data about your browsing activity and patterns of behaviour but do not identify you as an individual. This helps us to understand how people who visit our website use it, enabling us to improve the layout and content for visitors. It is possible to switch off cookies by setting your browser preferences and settings.</p>
<p>However, our website does not currently use cookies.</p>

<h2>Links to other websites</h2>
<p>Where we provide links to websites of other organisations, this privacy notice does not cover how that organisation processes personal information. We encourage you to read the privacy notices on the other websites you visit.</p>

<h2>How to make a complaint</h2>
<p>We strive to meet the highest standards when collecting and using personal information. For this reason, we take any complaints we may receive about this very seriously. We encourage people to inform us if they think that any collection or use of information by us is unfair, misleading or inappropriate. We would also welcome any suggestions for improving our procedures. You can do this by contacting us via email: <a href="mailto:help@frontierhealth.ai">help@frontierhealth.ai</a>.</p>
<p>If you remain dissatisfied, you have the right to make a complaint to the Information Commissioner’s Office (ICO). Please see the ICO’s website for more information: <a href="https://www.ico.org.uk">www.ico.org.uk</a>.</p>

<h2>Changes to this privacy notice</h2>
<p>We reserve the right to modify this Privacy Notice at any time, so please make sure to review this frequently. Our most updated Privacy Notice is posted on our website. All changes will apply with immediate effect.</p>

    </div>
  );
}

export default PrivacyNotice;
