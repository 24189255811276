import React from 'react';
import './Footer.css'; // You can style it using a CSS file

function Footer() {
  return (
    <footer className="footer">
      <p>© 2024 Frontier Health | <a href="/privacy-notice" className="footer-link">Privacy Notice</a></p>
    </footer>
  );
}

export default Footer;
